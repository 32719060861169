<template>
  <div>
    <Loader v-if="isLoading" />
    <div v-else>
      <h3 style="margin-bottom: 25px">{{ $l.get("user-edit.header") }}</h3>
      <UserForm
        :user="data.user"
        :onSubmit="onSubmit"
        v-if="data && data.user"
      />
    </div>
  </div>
</template>

<script>
import InitUserEditInteractor from "@/business/user-edit/init-user-edit";
import SubmitFormInteractor from "@/business/user-edit/submit-form";
import UserEditScreenController from "@/adapters/controllers/screen-user-edit";
import UserForm from "@/application/components/users/UserForm";
import Loader from "@/application/components/Loader";

export default {
  screen_name: "user-edit",
  components: { Loader, UserForm },
  data() {
    return {
      controller: null,
      interactors: {
        initUserEdit: null,
        submitForm: null
      },
      data: null,
      isLoading: false
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(UserEditScreenController);

    //{ INTERACTORS
    this.interactors.submitForm = this.$injector.get(SubmitFormInteractor);
    this.interactors.initUserEdit = this.$injector.get(InitUserEditInteractor);
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    const id = this.$route.params.id;
    this.interactors.initUserEdit.handle(id);
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    onSubmit() {
      this.interactors.submitForm.handle(this.data.user);
    }
  }
};
</script>

<style lang="scss"></style>
